exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-athletes-tsx": () => import("./../../../src/pages/athletes.tsx" /* webpackChunkName: "component---src-pages-athletes-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-templates-athlete-tsx": () => import("./../../../src/templates/athlete.tsx" /* webpackChunkName: "component---src-templates-athlete-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-gear-tsx": () => import("./../../../src/templates/gear.tsx" /* webpackChunkName: "component---src-templates-gear-tsx" */)
}

